@use "sass:math";

.maplibregl-map {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

$little-arrow-width: 8;
$little-arrow-height: 7;
// pythagoras yo
$rotated-arrow-width: calc(
  math.sqrt(
    math.pow($little-arrow-width, 2) + math.pow($little-arrow-height, 2)
  )
);
$bottom-space-for-arrow: calc(
  math.sqrt(
    math.pow($little-arrow-width, 2) - math.pow($rotated-arrow-width / 2, 2)
  )
);
// scss shenanigans
$little-arrow-width-px: $little-arrow-width + 0px;
$little-arrow-height-px: $little-arrow-height + 0px;
$bottom-space-for-arrow-px: $bottom-space-for-arrow + 0px;
$rotated-arrow-width-px: $rotated-arrow-width + 0px;

$marker-border-radius: 3px;
$marker-border-width: 2px;

.sc-marker {
  position: relative;

  &::after {
    content: "";
    @apply bg-bg-secondary-dark;
    border-left: $marker-border-width solid;
    border-bottom: $marker-border-width solid;
    position: absolute;
    transform: rotate(-45deg);
    //z-index: -1;
    top: calc(100% - $little-arrow-height-px);
    left: calc(50% - ($rotated-arrow-width-px / 2) + $marker-border-width);
    height: $little-arrow-height-px;
    width: $little-arrow-width-px;
    border-radius: $marker-border-radius;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: calc(100% - $bottom-space-for-arrow-px + 3px);
    top: 0;
    left: 0;
    content: "";
    border: $marker-border-width solid;
    border-radius: $marker-border-radius;
    @apply bg-bg-secondary-dark;
  }

  &.yellow::before,
  &.yellow::after {
    @apply border-bright-green;
  }
  &.red::before,
  &.red::after {
    @apply border-red;
  }
  &.green::before,
  &.green::after {
    @apply border-bright-green;
  }
  &.gray::before,
  &.gray::after {
    @apply border-focus-dark;
  }
}
