@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/manrope-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/manrope-v13-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/manrope-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/manrope-v13-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/manrope-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/manrope-v13-latin-regular.svg#Manrope") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/manrope-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/manrope-v13-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/manrope-v13-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/manrope-v13-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/manrope-v13-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/manrope-v13-latin-700.svg#Manrope") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/manrope-v13-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/manrope-v13-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/manrope-v13-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/manrope-v13-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/manrope-v13-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/manrope-v13-latin-800.svg#Manrope") format("svg"); /* Legacy iOS */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.dark .bg-gradient {
  background: linear-gradient(123deg, #3c4147 0%, #2e3137 100%) 0% 0% no-repeat;
}
.bg-gradient {
  background: linear-gradient(123deg, #ffffff 0%, #f7f7f7 100%) 0% 0% no-repeat;
}
body,
html {
  height: 100%;
  letter-spacing: 0.3px;
}

.font-bold {
  letter-spacing: 0.8px;
}

body {
  @apply dark:bg-bg-dark bg-bg-light dark:text-info-dark text-info-light;
}

#root {
  height: 100%;
}

.react-grid-item.react-grid-placeholder {
  @apply dark:bg-inactive-dark !bg-active-light rounded-lg;
}

.color-transition * {
}

.svg-thick * {
  stroke-width: 2px;
}

.svg-thicker * {
  stroke-width: 3.2px;
}

.svg-focus * {
  @apply dark:stroke-focus-dark stroke-focus-light;
}

.light svg:not(.icon-no-colormode) *:not(.icon-no-colormode) {
  @apply stroke-info-light;
}

.icon-no-colormode svg * {
}

.text-light {
  @apply dark:text-focus-dark text-focus-light;
}

.table-list th {
  @apply font-bold;
}

:focus-visible {
  outline: auto 0.5px;
  @apply outline-focus-light dark:outline-focus-dark;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  @apply dark:bg-focus-dark bg-focus-light;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0000004d;
  @apply dark:bg-inactive-dark bg-inactive-light;
}
